import React, { useEffect, useState, useRef } from 'react'
import { throttle } from 'throttle-debounce'

import { UStoreProvider } from '@ustore/core'
import { t } from '$themelocalization'

import { Slot } from '$core-components'
import Layout from '../components/layout'
import CategoryItem from '../components/category/CategoryItem'
import ProductItem from '../components/products/ProductItem'
import PromotionItem from '../components/products/PromotionItem'
import { Slider, Gallery } from '$core-components'

import { getIsNGProduct } from '$themeservices/utils'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import theme from '$styles/_theme.scss'
import AnimatedSlider from '$core-components/AnimatedSlider'
import Testimonials from '$core-components/Testimonials'
import './Home.scss'
import FeedBack from 'react-feedback-popup';
import emailjs from '@emailjs/browser'


const Home = (props) => {
  const { customState: { categories } } = props


  const [isMobile, setIsMobile] = useState(false)
  const [promotionItemButtonUrl, setPromotionItemButtonUrl] = useState('')
  const [homeFeaturedProducts, setHomeFeaturedProducts] = useState(null)
  const [homeFeaturedCategory, setHomeFeaturedCategory] = useState(null)

  const promotionItemImageUrl = getVariableValue('--homepage-carousel-slide-1-image', require(`$assets/images/banner_image.png`), true)
  const promotionItemTitle = getVariableValue('--homepage-carousel-slide-1-main-text', t('PromotionItem.Title'))
  const promotionItemSubtitle = getVariableValue('--homepage-carousel-slide-1-sub-text', t('PromotionItem.Subtitle'))
  const promotionItemButtonText = getVariableValue('--homepage-carousel-slide-1-button-text', t('PromotionItem.Button_Text'))
  const onResize = () => setIsMobile(document.body.clientWidth < parseInt(theme.md.replace('px', '')))

  useEffect(() => {
    window.addEventListener('resize', onResize.bind(this))
    throttle(250, onResize);// Call this function once in 250ms only

    (async () => {
      const maxInPage = 200
      let { Count, Categories } = await UStoreProvider.api.categories.getTopCategories(1, maxInPage)
      if (Count === 0) {
        return
      }

      const page = Math.ceil(Count / maxInPage)
      if (page > 1) {
        const { Categories: lastPageCategories } = await UStoreProvider.api.categories.getTopCategories(page, maxInPage)
        if (lastPageCategories.length > 0) {
          Categories = lastPageCategories
        }
      }

      const homeFeaturedCategory = Categories[Categories.length - 1]
      const { Products } = await UStoreProvider.api.products.getProducts(homeFeaturedCategory.ID, 1)
      setHomeFeaturedProducts(Products)
      setHomeFeaturedCategory(homeFeaturedCategory)
    })()

    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      clearCustomState()
    }
  }, [])

  useEffect(() => {
    if (categories && categories.length && !promotionItemButtonUrl) {
      const { FriendlyID, Name } = categories[0]
      const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
      setPromotionItemButtonUrl(getVariableValue('--homepage-carousel-slide-1-button-url', defaultURL, false, true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customState, categories])

  if (!props.state) {
    return null
  }
 
         // braam add email

  return (
    <Layout {...props} className="home">
      <Slot name="homepage_top"/>
      {/* BRAAM add feedback*/}
      <FeedBack
				style={{zIndex:'2', marginLeft:'20px', position:'fixed'}}
				position="left"
				numberOfStars={5}
				headerText="Hello"
				bodyText="Please give us your feedback or recommendations"
				buttonText="Give us Feedback"
				handleClose={() => console.log("handleclose")}
        handleSubmit={(data) => 



            emailjs.send('Remata', 'template_rir3hlx', {
             message: JSON.stringify(data)}, 'JUJM7eBoTA6mG5_qM') .then((result) => {
              console.log(result.text);
              console.log("message sent");
             // alert('Message sent successfully! We will get back to you asap.')
             window.location.replace('https://onlineprintshop.remata.co.za/en-ZA/ThankYou/');
          }, (error) => {
              console.log(error.text);
              alert(`${error.text} happened`);

          })


        }
     
        handleButtonClick={() => console.log("handleButtonClick")}
      
      />
      <AnimatedSlider {...props} />

      <div className="middle-section">
        {categories && categories.length > 0 &&
          <div className="categories-wrapper">
            <Slider multi>
              {
                categories.map((model) => {
                    return <CategoryItem key={model.ID} model={model}
                                         url={urlGenerator.get({
                                           page: 'category',
                                           id: model.FriendlyID,
                                           name: decodeStringForURL(model.Name)
                                         })}/>
                  }
                )
              }
            </Slider>
          </div>
        }

       {/* BRAAM add paygate and testimonials*/}
       <div className="divider" /></div>
        <Testimonials {...props} />
        <div className="divider" />
        <div className='paygate'>
        <img src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\PayGate-Card-Brand-Logos-PayGate-SML.png' alt='Payment Options'></img>
        </div> 

    </Layout>
  )

}

export default Home

function clearCustomState () {
  UStoreProvider.state.customState.delete('homeFeaturedProducts')
  UStoreProvider.state.customState.delete('homeFeaturedCategory')
  UStoreProvider.state.customState.delete('currentProduct')
  UStoreProvider.state.customState.delete('currentOrderItem')
  UStoreProvider.state.customState.delete('currentOrderItemId')
  UStoreProvider.state.customState.delete('currentOrderItemPriceModel')
  UStoreProvider.state.customState.delete('lastOrder')
  UStoreProvider.state.customState.delete('currentProductThumbnails')
}
