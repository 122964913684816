import React from 'react'
import { Link } from 'react-router-dom'
import HTMLLinesEllipsis from 'react-lines-ellipsis/lib/html'
import './CategoryItem.scss'

/**
 * This component represents the category in the store
 * It contains the category image and details
 *
 * @param {object} model - the category
 * @param {string} url - the url to redirects when clicking the category
 * @param {string} [className] - the css class to add to main div
 */

const CategoryItem = ({ model, url, className }) => {
  if (!model) {
    return <div className="category-item" />
  }
  const imageUrl = model.ImageUrl ? model.ImageUrl : require(`$assets/images/default.png`)

                  /* --add img alt and title - braam */

  var imagealt = "";
  var imagetitle = "";
  if(model.Name === "Printing (Litho / Digital"){
    imagealt = "Custom Online Printing | Custom Litho Printing | Custom Digital Printing";
    imagetitle = "Remata Online Print Shop | Custom Printing | Litho / Digital"
              }
  else if(model.Name === "Packaging / Boxes"){
     imagealt = "Custom Online Printing | Custom Print Packages | Custom Print Boxes";
     imagetitle = "Remata Online Print Shop | Custom Printing | Packages & Boxes"
              }
  else if(model.Name === "Point of Sale"){
     imagealt = "Custom Online Printing | Custom Point of Sale Printing";
     imagetitle = "Remata Online Print Shop | Custom Printing | Point of Sale"
              }     
  else if(model.Name === "Outdoor Products"){
     imagealt = "Custom Online Printing | Custom Outdoor Product Printing";
     imagetitle = "Remata Online Print Shop | Custom Printing | Outdoor Products"
              }     
  else if(model.Name === "Gifts"){
     imagealt = "Custom Online Printing | Custom Gift Printing";
     imagetitle = "Remata Online Print Shop | Custom Printing | Custom Gifts"
              }     			  
  else if(model.Name === "Pop-Up Banners"){
     imagealt = "Pop Up Banner";
     imagetitle = "Pop Up Banner | Outdoor Products"
              }   
  else if(model.Name === "A-Frame Banners"){
     imagealt = "A-Frame Banners";
     imagetitle = "A-Frame Banners | Outdoor Products"
              }   
  else if(model.Name === "Deluxe Wall Banners"){
     imagealt = " Delux Wall Banners";
     imagetitle = "Delux Wall Banners | Outdoor Products"
              }   
  else if(model.Name === "Curved Banner Flag"){
     imagealt = "Curved Banner Flag";
     imagetitle = "Curved Banner Flag | Outdoor Products"
              }   
  else if(model.Name === "Pull-Up Banners"){
     imagealt = "Pull-Up Banners";
     imagetitle = "Pull up Banner | Outdoor Products"
              }   
  else if(model.Name === "Teardrop Banner Flags"){
     imagealt = "Teardrop Banner Flags";
     imagetitle = "Teardrop Banner Flag | Outdoor Products"
              }   
  else if(model.Name === "Telescopic Banner Flags"){
     imagealt = "Telescopic Banner Flags";
     imagetitle = "Telescopic Banner Flags | Outdoor Products"
              }   	
  else if(model.Name === "Stationery"){
     imagealt = "Custom Online Printing | Stationary";
     imagetitle = "Custom Stationary Printing"
              }   		
  else if(model.Name === "Flyers And Booklets"){
     imagealt = "Custom Online Printing | Flyers | Booklets";
     imagetitle = "Custom Flyer Printing | Custom Booklet Printing"
              }  
  else if(model.Name === "Promotional & Marketing Material - Litho & Digital Printing"){
     imagealt = "Custom Online Printing | Promotional Material | Marketing Material";
     imagetitle = "Custom Marketing Material Printing | Custom Promotional Material Printing"
              }  
  else if(model.Name === "Indoor Displays"){
     imagealt = "Custom Online Printing | Indoor Displays";
     imagetitle = "Custom Indoor Display Printing"
              }  	
  else if(model.Name === "Corrugated Boxes"){
     imagealt = "Corrugated Boxes | Custom Box Printing | Custom Package Printing";
     imagetitle = "Custom Corrugated Box & Packaging Printing"
              }  
  else if(model.Name === "Carton Boxes - Packaging & Boxes"){
     imagealt = "Carton Boxes | Custom Box Printing | Custom Package Printing";
     imagetitle = "Custom Carton Box & Packaging Printing"
              }  
  else if(model.Name === "Signage"){
     imagealt = "Signane | Custom Signage Online Printing";
     imagetitle = "Custom Point of Sale Printing | Sigane"
              }  
  else if(model.Name === "Promo Items"){
     imagealt = "Promo Items | Custom Promotional Online Printing";
     imagetitle = "Custom Point of Sale Printing | Promo Items"
              }  
  else if(model.Name === "Indoor Display - Point of Sale Displays"){
     imagealt = "Indoor Displays | Point of Sale Displays | Custom Display Printing";
     imagetitle = "Custom Point of Sale Printing | Indoor Displays | Point of Sale Displays"
              }  
  else if(model.Name === "Compliment Slips"){
     imagealt = " Compliment Slips | Custom Compliment Slip Printing Online";
     imagetitle = "Compliment Slips | Custom Compliment Slip Printing Online"
              }  
  else if(model.Name === "Cards / Postcards"){
     imagealt = "Cards/Postcards | Custom Cards/Postcards Printing Online";
     imagetitle = "Cards/Postcards | Custom Cards/Postcards Printing Online"
              }  
  else if(model.Name === "Bookmarks"){
     imagealt = "Bookmarks | Custom Bookmarks Printing Online";
     imagetitle = "Bookmarks | Custom Bookmarks Printing Online"
              }  
  else if(model.Name === "Invitations"){
     imagealt = "Invitations | Custom Invitation Printing Online";
     imagetitle = "Invitations | Custom Invitation Printing Online"
              }  
  else if(model.Name === "Certificates"){
     imagealt = "Certificates | Custom Certificate Printing Online";
     imagetitle = "Certificates | Custom Certificate Printing Online"
              }  
  else if(model.Name === "NCR Books"){
     imagealt = "NCR Books | Custom NCR Books Printing Online";
     imagetitle = "NCR Books | Custom NCR Books Printing Online"
              }  
  else if(model.Name === "Wall Calendars"){
     imagealt = "Wall Calenders | Custom Wall Calender Printing Online";
     imagetitle = "Wall Calenders | Custom Wall Calender Printing Online"
              }  
  else if(model.Name === "Desk Pad Calendars"){
     imagealt = "Desk Pad Calenders | Custom Desk Pad Calenders Online Printing";
     imagetitle = "Desk Pad Calenders | Custom Desk Pad Calenders Online Printing"
              }  
  else if(model.Name === "Multipage Tent Calendars"){
     imagealt = "Multipage Tent Calenders | Multipage Tent Calenders";
     imagetitle = "Multipage Tent Calenders | Multipage Tent Calenders"
              }  
  else if(model.Name === "Business Cards"){
     imagealt = "Business Cards | Custom Business Card Printing Online";
     imagetitle = "Business Cards | Custom Business Card Printing Online"
              }  
  else if(model.Name === "Folders"){
     imagealt = "Folders | Custom Folder Printing Online";
     imagetitle = "Folders | Custom Folder Printing Online"
              }  
  else if(model.Name === "Letterheads"){
     imagealt = "Letterheads | Custom Letterhead design and Printing Online";
     imagetitle = "Letterheads | Custom Letterhead design and Printing Online"
              }  		
  else if(model.Name === "Gifts"){
      imagealt = "Custom Online Printing | Gifts";
       imagetitle = "Remata Online Print Shop | Gifts"
                        }  	
  else if(model.Name === "Indoor Displays"){
        imagealt = "Custom Online Printing | Indoor Display";
         imagetitle = "Remata Online Print Shop | Indoor Display"
                                             }       
  else if(model.Name === "Tags"){
         imagealt = "Custom Online Printing | Tags";
          imagetitle = "Remata Online Print Shop | Point of Sale Tags"
                                                                                     }          	  
  else {
     imagealt = model.Name;
     imagetitle = model.Name
        }



  return (
    <Link to={url} className={`category-item ${className || ''}`} data-qaautomationinfo={model.FriendlyID}>
      <div className="image-wrapper">


        <img alt={imagealt} title={imagetitle} src={imageUrl} />
      </div>
      <div className="category-name">
        <h2><HTMLLinesEllipsis style={{ whiteSpace: 'pre-wrap' }} unsafeHTML={model.Name} maxLine={2} basedOn='words' /></h2>
      </div>
    </Link>
  )
}

export default CategoryItem
